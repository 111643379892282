import React, { useEffect, useRef, useState } from 'react';
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import MarkerImage from "./custom_marker.png";
import { nanoid } from 'nanoid';

import { Map, Layer, Marker, useMap, MapRef, ScaleControl, GeolocateControl, NavigationControl } from 'react-map-gl';
import mapboxgl, { FillExtrusionLayer, CustomLayerInterface, SymbolLayer } from 'mapbox-gl';

interface Area {
    name: string;
    Location: {
        coordinates: [number, number]
    }
  }

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const mapCenterCoor: [number, number] = [100.61134507546271, 13.68515190000507] // TDPK

const specialAreas = [
    {
        name: "TRUE Digital Park",
        latAndLng: [13.685581946398138, 100.60994426635075]
    }
]

export default function ScanningAreaMap() {
    const { current: map } = useMap();
    const mapRef = useRef<MapRef | null>(null);
    const animateRef = useRef<number>(0);
    const [data, setData] = useState<Area[]>([]);

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "x-graff-mappers",
        "d93e8b0b-43d9-4152-ab78-da3690ca0722-48ba1748-38bd-4c36-83c7-359f06c33de4-2349d85e-12e5-4472-8218-37ad18164c76"
      );
    
      var raw = "";
    
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as const,
      };
      const fetchArea = async () => {
        try {
          const response = await fetch(
            "https://graffity-mappers-backend-kakvtlg4pa-as.a.run.app/api/v1/place/get-places-to-scan",
            requestOptions
          );
          const data = await response.json();
    
          setData(data);
        //   console.log(data);
          // .then((response) => response.text())
          // .then((result) => console.log(result));
        } catch (error) {
          console.log("Error fetching places:", error);
        }
      };
      fetchArea();
    }, []);
    

    const buildingLayer: FillExtrusionLayer = {
        id: '3d-buildings',
        type: 'fill-extrusion',
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        minzoom: 15,
        paint: {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
            ],
            'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.15
        }
    };

    const animate = () => {
        requestAnimationFrame(animate);
        // renderer.render(scene, camera);
    }
    // animate();

    const getModelLayers = (): CustomLayerInterface[] => {

        const layers = data.map(area => {
            let camera: THREE.Camera;
            let scene: THREE.Scene;
            let renderer: THREE.WebGLRenderer;
            let mixer: THREE.AnimationMixer;

            const modelOrigin: [number, number] = [area.Location.coordinates[0], area.Location.coordinates[1]];
            const modelAltitude = 0;
            const modelRotate = [Math.PI / 2, 0, 0];

            const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                modelOrigin,
                modelAltitude
            );

            const modelTransform = {
                translateX: modelAsMercatorCoordinate.x,
                translateY: modelAsMercatorCoordinate.y,
                translateZ: modelAsMercatorCoordinate.z!,
                rotateX: modelRotate[0],
                rotateY: modelRotate[1],
                rotateZ: modelRotate[2],
                /* Since the 3D model is in real world meters, a scale transform needs to be
                * applied since the CustomLayerInterface expects units in MercatorCoordinates.
                */
                scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 10
            };

            const coinModelLayer: CustomLayerInterface = {
                id: nanoid(),
                type: 'custom',
                renderingMode: '3d',
                onAdd: function (map, gl) {
                    camera = new THREE.PerspectiveCamera();
                    scene = new THREE.Scene();

                    // create two three.js lights to illuminate the model
                    const directionalLight = new THREE.DirectionalLight(0xffffff);
                    directionalLight.position.set(0, -70, 100).normalize();
                    scene.add(directionalLight);

                    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                    directionalLight2.position.set(0, 70, 100).normalize();
                    scene.add(directionalLight2);

                    // use the three.js GLTF loader to add the 3D model to the three.js scene
                    const loader = new GLTFLoader();
                    loader.load(
                        'https://graffity-sdk-public.s3.ap-southeast-1.amazonaws.com/3d-samples/coin_5for_sketchfab.glb',
                        (gltf) => {
                            // mixer = new THREE.AnimationMixer(gltf.scene);
                            // const animationAction = mixer.clipAction(gltf.animations[0]);
                            // animationAction.loop = THREE.LoopRepeat;
                            // animationAction.play();
                            scene.add(gltf.scene);

                            // animate();
                        }
                    );

                    // use the Mapbox GL JS map canvas for three.js
                    renderer = new THREE.WebGLRenderer({
                        canvas: map.getCanvas(),
                        context: gl,
                        antialias: true
                    });

                    renderer.autoClear = false;

                    // animate();
                },
                render: function (gl, matrix) {
                    const rotationX = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(1, 0, 0),
                        modelTransform.rotateX
                    );
                    const rotationY = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 1, 0),
                        modelTransform.rotateY
                    );
                    const rotationZ = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 0, 1),
                        modelTransform.rotateZ
                    );

                    const m = new THREE.Matrix4().fromArray(matrix);
                    const l = new THREE.Matrix4()
                        .makeTranslation(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ
                        )
                        .scale(
                            new THREE.Vector3(
                                modelTransform.scale,
                                -modelTransform.scale,
                                modelTransform.scale
                            )
                        )
                        .multiply(rotationX)
                        .multiply(rotationY)
                        .multiply(rotationZ);

                    camera.projectionMatrix = m.multiply(l);
                    renderer.resetState();
                    renderer.render(scene, camera);
                    if (!map) return;
                    map.triggerRepaint();
                }
            }

            return coinModelLayer;
        });

        return layers;
    }

    const getBoxModelLayers = (): CustomLayerInterface[] => {
        const layers = specialAreas.map(area => {
            let camera: THREE.Camera;
            let scene: THREE.Scene;
            let renderer: THREE.WebGLRenderer;
            let mixer: THREE.AnimationMixer;

            const modelOrigin: [number, number] = [area.latAndLng[1] + 0.0005, area.latAndLng[0] + 0.0005];
            const modelAltitude = 0;
            const modelRotate = [Math.PI / 2, 0, 0];

            const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                modelOrigin,
                modelAltitude
            );

            const modelTransform = {
                translateX: modelAsMercatorCoordinate.x,
                translateY: modelAsMercatorCoordinate.y,
                translateZ: modelAsMercatorCoordinate.z!,
                rotateX: modelRotate[0],
                rotateY: modelRotate[1],
                rotateZ: modelRotate[2],
                /* Since the 3D model is in real world meters, a scale transform needs to be
                * applied since the CustomLayerInterface expects units in MercatorCoordinates.
                */
                scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 10
            };

            const coinModelLayer: CustomLayerInterface = {
                id: nanoid(),
                type: 'custom',
                renderingMode: '3d',
                onAdd: function (map, gl) {
                    camera = new THREE.PerspectiveCamera();
                    scene = new THREE.Scene();

                    // create two three.js lights to illuminate the model
                    const directionalLight = new THREE.DirectionalLight(0xffffff);
                    directionalLight.position.set(0, -70, 100).normalize();
                    scene.add(directionalLight);

                    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                    directionalLight2.position.set(0, 70, 100).normalize();
                    scene.add(directionalLight2);

                    // use the three.js GLTF loader to add the 3D model to the three.js scene
                    const loader = new GLTFLoader();
                    loader.load(
                        'https://graffity-public-assets.s3.ap-southeast-1.amazonaws.com/3D/question_block_yellow.glb',
                        (gltf) => {
                            // mixer = new THREE.AnimationMixer(gltf.scene);
                            // const animationAction = mixer.clipAction(gltf.animations[0]);
                            // animationAction.loop = THREE.LoopRepeat;
                            // animationAction.play();
                            scene.add(gltf.scene);

                            // animate();
                        }
                    );

                    // use the Mapbox GL JS map canvas for three.js
                    renderer = new THREE.WebGLRenderer({
                        canvas: map.getCanvas(),
                        context: gl,
                        antialias: true
                    });

                    renderer.autoClear = false;

                    // animate();
                },
                render: function (gl, matrix) {
                    const rotationX = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(1, 0, 0),
                        modelTransform.rotateX
                    );
                    const rotationY = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 1, 0),
                        modelTransform.rotateY
                    );
                    const rotationZ = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 0, 1),
                        modelTransform.rotateZ
                    );

                    const m = new THREE.Matrix4().fromArray(matrix);
                    const l = new THREE.Matrix4()
                        .makeTranslation(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ
                        )
                        .scale(
                            new THREE.Vector3(
                                modelTransform.scale,
                                -modelTransform.scale,
                                modelTransform.scale
                            )
                        )
                        .multiply(rotationX)
                        .multiply(rotationY)
                        .multiply(rotationZ);

                    camera.projectionMatrix = m.multiply(l);
                    renderer.resetState();
                    renderer.render(scene, camera);
                    if (!map) return;
                    map.triggerRepaint();
                }
            }

            return coinModelLayer;
        });

        return layers;
    }

    const onMarkerClicked = (lat: number, lng: number) => {
        console.log("onMarkerClicked")
        if (!mapRef.current) return;
        console.log("onMarkerClicked 2")
        mapRef.current!.flyTo({ center: [lng, lat], zoom: 18 });
        // mapRef.current!.setPitch(75);
    }

    // useEffect(() => {
    //     animateRef.current = requestAnimationFrame(animate);
    //     return () => cancelAnimationFrame(animateRef.current);
    // }, []);

    return (
        <React.Fragment>
            <Map
                ref={mapRef}
                initialViewState={{
                    latitude: mapCenterCoor[1],
                    longitude: mapCenterCoor[0],
                    zoom: 14,
                    pitch: 60, // VIEW ORIENTATION
                }}
                style={{ width: "100vw", height: "100vh" }}
                mapStyle="mapbox://styles/mapbox/dark-v11"
                mapboxAccessToken={MAPBOX_TOKEN}
                customAttribution="© Graffity Technologies Co., Ltd."
            >
                <NavigationControl />
                {
                    data.map(area => {
                        return <Marker
                            longitude={area.Location.coordinates[0]}
                            latitude={area.Location.coordinates[1]}
                            anchor="bottom"
                            scale={0.25}
                            onClick={() => onMarkerClicked(area.Location.coordinates[1], area.Location.coordinates[0])}
                        >
                            <div className='marker-container'>
                                <img src={MarkerImage} />
                                <p className='marker-label-text'>{area.name}</p>
                            </div>
                        </Marker>
                    })
                }


                {
                    getModelLayers().map(layer => <Layer {...layer} />)
                }
                {
                    getBoxModelLayers().map(layer => <Layer {...layer} />)
                }
                <Layer {...buildingLayer} />
            </Map>
        </React.Fragment>
    )
}