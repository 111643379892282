import React from "react";
import { Col } from "reactstrap";
import mappersHowToIMG from "../assets/images/mappers-how-to.jpg";

export default function StartScan() {
  return (
    <React.Fragment>
      <div style={{
          marginTop: "30px",
          marginLeft: "40px",
          marginRight: "40px",
        }}>
      <img src={mappersHowToIMG} width="100%" />
      </div>
      <div
        style={{
          marginTop: "30px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <Col>
          <p style={{ fontSize: '18px', margin: 0 }}>1. Allow app to access camera</p>
          <p style={{ fontSize: '14px', color: 'gray' }}>App need camera access to start scan</p>
        </Col>
        <Col>
          <p style={{ fontSize: '18px', margin: 0 }}>2. Allow app to access location</p>
          <p style={{ fontSize: '14px', color: 'gray' }}>App also need location access to track each image position</p>
        </Col>

        <Col>
          <p style={{ fontSize: '18px', margin: 0 }}>
            3. Hold camera as stavl as you can to capture environment around you
          </p>
          <p style={{ fontSize: '14px', color: 'gray' }}>
            Toggle start scan when you reaady and securely hold camera while you
            scanning
          </p>
        </Col>
        <Col>
          <p style={{ fontSize: '18px', margin: 0 }}>4. Pick your good barch to upload</p>
          <p style={{ fontSize: '14px', color: 'gray' }}>You can delete your defect batch and only upload good ones</p>
        </Col>
      </div>
    </React.Fragment>
  );
}
