import React from "react";
import { Input, InputGroup } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineMail } from "react-icons/ai";
import { RiBallPenFill } from "react-icons/ri";

export default function YourInfos() {
  const email = "email"
  const name = "name"

  return (
    <React.Fragment>
      <div
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <InputGroup style={{ height: "50px",marginBottom:"8px" }}>
          <span className="input-group-text">
            <AiOutlineMail />
          </span>
          <Input style={{ fontSize: '14px' }} placeholder={email} disabled/>
        </InputGroup>
        <InputGroup style={{ height: "50px" }}>
          <span className="input-group-text">
            <RiBallPenFill />
          </span>
          <Input style={{ fontSize: '14px' }} placeholder={name} disabled />
        </InputGroup>
      </div>
    </React.Fragment>
  );
}
