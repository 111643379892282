import React from "react";
import { Button, Input, InputGroup } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tb123 } from "react-icons/tb";
import { RiBankLine } from "react-icons/ri";
import { PiPersonArmsSpreadFill } from "react-icons/pi";

export default function PaymentAccount() {
  return (
    <React.Fragment>
      <div
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <p 
          style={{
            marginBottom: "30px", fontSize: "14px"
          }}
        >
          We'll transfer your reward to this bank account. Now, only support in
          Thailand
        </p>
        <InputGroup style={{ height: "50px",marginBottom:"8px" }}>
          <span className="input-group-text">
            <Tb123 />
          </span>
          <Input style={{ fontSize: "14px" }} placeholder="Account Number" />
        </InputGroup>
        <InputGroup style={{ height: "50px",marginBottom:"8px" }}>
          <span className="input-group-text">
            <RiBankLine />
          </span>
          <Input style={{ fontSize: "14px" }} placeholder="Bank Name" />
        </InputGroup>
        <InputGroup style={{ height: "50px" }}>
          <span className="input-group-text">
            <PiPersonArmsSpreadFill />
          </span>
          <Input style={{ fontSize: "14px" }} placeholder="Your Account Name" />
        </InputGroup>

        <Button
          color="warning"
          style={{ marginTop: "40px", width: "100%", color: "white" }}
        >
          <b>Save</b>
        </Button>
      </div>
    </React.Fragment>
  );
}
