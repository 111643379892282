import React, { useEffect, useState } from "react";

export default function Test() {
  const [result, setResult] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-graff-mappers",
      "d93e8b0b-43d9-4152-ab78-da3690ca0722-48ba1748-38bd-4c36-83c7-359f06c33de4-2349d85e-12e5-4472-8218-37ad18164c76"
    );

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as const,
    };

    fetch(
      "https://graffity-mappers-backend-kakvtlg4pa-as.a.run.app/api/v1/place/get-places-to-scan",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setResult(result);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <React.Fragment>
      <div>
        <div
          style={{ marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}
        >
          <p>
            The reward is up to your scanning quality, how long it takes you to
            scan, and the distance factor for each batch.
          </p>

          <p>
            This is estimated rate for a high-quality batch in Thailand baht.
          </p>

          <p>{result}</p>
        </div>
      </div>
    </React.Fragment>
  );
}
