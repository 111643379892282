import React from "react";

export default function AbtMappers() {
  return (
    <React.Fragment>
      <div
        style={{
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          fontSize: "14px"
        }}
      >
        <p>
          Graffity Mappers is a platform that lets you get a reward back while
          scanning the world's visual data. We will offer a reward as cash,
          credit, or cryptocurrency up to your country's regulation.
        </p>

        <p>
          Your scanning result is point clouds represent the world's 3D spatial
          data. We were using those points for Visual Positioning Service (VPS).
          So, your reward is up to your scanning quality
        </p>

        <p>
          We plan to give everyone the power to create the next infrastructure
          for a digital world using just a smartphone in your pocket.
        </p>

        <p>Email us to filling any issues at <br/>mappers@graffity.tech</p>
        <p>Thank you for joining us. <br/> Graffity Team</p>
      </div>
    </React.Fragment>
  );
}
