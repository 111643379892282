import { Routes, Route } from "react-router-dom"

import './App.css';
import AbtMappers from "./pages/AbtMappers";
import EarningRate from "./pages/EarningRate";
import PaymentAccount from "./pages/PaymentAccount";
import ScanningArea from "./pages/ScanningArea";
import ScanningAreaMap from "./pages/ScanningAreaMap";
import StartScan from "./pages/StartScan";
import Test from "./pages/Test";
import YourInfos from "./pages/YourInfos";


function App() {
  return (
    <Routes>
      {/* PUBLIC PAGES */}
      <Route path='/scanning-area/mapview' element={<ScanningAreaMap />} />
      <Route path='/scanning-area/listview' element={<Root />} />
      <Route path='/earning-rate' element={<EarningRate />} />
      <Route path='/scanning-area' element={<ScanningArea />} />
      <Route path='/start-scan' element={<StartScan />} />
      <Route path='/your-infos' element={<YourInfos />} />
      <Route path='/payment-account' element={<PaymentAccount />} />
      <Route path='/about-mappers' element={<AbtMappers />} />
      <Route path='/test' element={<Test />} />
      <Route path='*' element={<Root />} />
    </Routes>
  );
}

function Root() {
  return (
    <div>555555555</div>
  )
}

export default App;
