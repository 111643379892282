import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { IconContext } from "react-icons";
import { SlLocationPin } from "react-icons/sl";

interface Area {
  name: string;
  city: string;
  state: string;
  country_code: string;
  location_link: string;
}

export default function ScanningArea() {
  const [data, setData] = useState<Area[]>([]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "x-graff-mappers",
      "d93e8b0b-43d9-4152-ab78-da3690ca0722-48ba1748-38bd-4c36-83c7-359f06c33de4-2349d85e-12e5-4472-8218-37ad18164c76"
    );

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as const,
    };
    const fetchArea = async () => {
      try {
        const response = await fetch(
          "https://graffity-mappers-backend-kakvtlg4pa-as.a.run.app/api/v1/place/get-places-to-scan",
          requestOptions
        );
        const data = await response.json();

        setData(data);
        // console.log(data);
        // .then((response) => response.text())
        // .then((result) => console.log(result));
      } catch (error) {
        console.log("Error fetching places:", error);
      }
    };
    fetchArea();
  }, []);

  const DisplayData = data.map((info) => {
    return (
      <Button
        color="light"
        href={info.location_link}
        style={{
          marginBottom: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ marginRight: "1rem" }}>
          <IconContext.Provider value={{ color: "orange " }}>
            <SlLocationPin size={23} />
          </IconContext.Provider>
        </span>
        <div style={{ marginTop: "7px", marginBottom: "7px",flex: 1, textAlign: "left" }}>
          <p style={{ fontSize: '17px', margin: 0 }}>{info.name}</p>
          <p style={{ fontSize: '12px', margin: 0, color: "gray" }}>{info.city}</p>
        </div>
        <div style={{ textAlign: "right" }}>
          <p style={{ fontSize: '12px', margin: 0, color: "gray" }}>{info.state}</p>
          <p style={{ fontSize: '14px', margin: 0 }}>{info.country_code}</p>
        </div>
      </Button>
    );
  });

  return (
    <React.Fragment>
      <div>
        <div
          style={{ marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}
        >
          {DisplayData}
        </div>
      </div>
    </React.Fragment>
  );
}
