import React from "react";

export default function EarningRate() {
  return (
    <React.Fragment>
      <div>
        <div
          style={{ fontSize: "14px",marginTop: "20px", marginLeft: "20px", marginRight: "20px" }}
        >
          <p>
            The reward is up to your scanning quality, how long it takes you to
            scan, and the distance factor for each batch.
          </p>

          <p>
            This is estimated rate for a high-quality batch in Thailand baht.
          </p>

          <p>Scan ~5 minutes to Get 1 Mappers Coin</p>
        </div>
      </div>
    </React.Fragment>
  );
}
